import React from 'react';
import { FaLinkedin, FaFacebookSquare, FaTwitterSquare } from 'react-icons/fa';
import Button from './Button';

const ShareToMedia = ({ link, title, description, media }) => {
  const shareIcon =
    media === 'facebook' ? (
      <FaFacebookSquare />
    ) : media === 'linkedin' ? (
      <FaLinkedin />
    ) : media === 'twitter' ? (
      <FaTwitterSquare />
    ) : (
      ''
    );

  const shareToMedia = () => {
    const encodedLink = encodeURIComponent(link);
    const encodedTitle = encodeURIComponent(title);
    const encodedDescription = encodeURIComponent(description);
    if (media === 'facebook') {
      window.open(
        `https://www.facebook.com/sharer/sharer.php?u=${encodedLink}&quote=${encodedTitle} ${encodedDescription}`,
        '_blank',
        'height=400,width=500'
      );
    } else if (media === 'linkedin') {
      window.open(
        `https://www.linkedin.com/shareArticle?mini=true&url=${encodedLink}&title=${encodedTitle}&summary=${encodedDescription}`,
        '_blank',
        'height=400,width=500'
      );
    } else if (media === 'twitter') {
      const twitterUrl = `https://twitter.com/share?url=${encodeURIComponent(encodedLink)}&text=${encodeURIComponent(
        encodedTitle
      )}&via=yourTwitterHandle&hashtags=yourHashtags&description=${encodeURIComponent(encodedDescription)}`;
      window.open(twitterUrl, 'Twitter', 'width=550, height= 400, toolbar= 0, scrollbars= 1, status= 0, resizable= 1');
    }
  };

  return (
    <Button
      handleClick={() => shareToMedia()}
      aria_label={`Share on ${media}`}
      data_title={`Share on ${media}`}
      text={shareIcon}
    />
  );
};

export default ShareToMedia;
