import React from 'react';
import { experiencesData } from './ExperienceData';
import { useState } from 'react';
import { useEffect } from 'react';
import AnimatedLetters from '../AnimatedLetters/AnimatedLetters';
import './Experiences.scss';
import Loader from 'react-loaders';
import anime from 'animejs';
import SEO from '../Hooks/SEO';

const Experiences = () => {
  const [letterClass, setLetterClass] = useState('text-animate');
  const datas = experiencesData();
  // useDocumentTitle('Nazmus - Experinences ✨');

  useEffect(() => {
    setTimeout(() => {
      setLetterClass('text-animate-hover');
    }, 3000);
  }, []);

  useEffect(() => {
    const image = document.querySelectorAll('.ex-data');
    anime({
      targets: image,
      translateX: [300, 0],
      translateY: [0],
      duration: 3000,
      loop: false,
      easing: 'easeInOutSine'
    });
  }, []);

  return (
    <>
      <div className="container experiences-page">
        <SEO
          description="Frontend Engineer at Devxhub, former Frontend Engineer at Nexuses, C-Sunshine, and Brillmark LLC. Experiences with more than 3 years in Frontend with Javascript, React, Nextjs."
          title="Nazmus - Experinences ✨"
          type="Experinences in Frontend with Javascript, React, NextJs Development"
          href="https://nazmus-home.netlify.app/experiences"
        />
        <div className="text-zone">
          <h1>
            <AnimatedLetters
              letterClass={letterClass}
              strArray={['P', 'r', 'o', 'f', 'e', 's','s', 'i', 'o', 'n', 'a','l',' ', 'E', 'x', 'p', 'e', 'r', 'i', 'e', 'n', 'c', 'e', 's']}
              idx={25}
            />
          </h1>
          <div className="ex-contents">
            {datas.map((data, index) => (
              <div className="ex-data" aria-label="Experience data" key={index}>
                <div className="top">
                  <div className="left">
                    <h3 aria-label={data.companyName}>
                      <a href={data.companyUrl} target="_blank" rel="noopener noreferrer">
                        {data.companyName}
                      </a>
                    </h3>
                    <h5 aria-label={data.role}>{data.role}</h5>
                  </div>
                  <div className="right">
                    <p aria-label={data.periods}>{data.periods}</p>
                  </div>
                </div>
                <div className="body">
                  <ul>
                    {data.descriptions.map((des, index) => (
                      <li aria-label={des} key={index}>
                        {des}
                      </li>
                    ))}
                  </ul>
                  {data.projects.length > 0 && data.projects[0] && (
                    <p className="projects">
                      <strong>Projects:{' '}</strong>
                      {data.projects.map(
                        (project, i) =>
                          project &&
                          data.projectsName[i] && (
                            <span key={i}>
                              {i > 0 && ', '}
                              <a href={project} target="_blank" rel="noopener noreferrer">
                                {data.projectsName[i]}
                              </a>
                            </span>
                          )
                      )}
                    </p>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <Loader type="pacman" />
    </>
  );
};

export default Experiences;
