import React from 'react';
import LazyLoadImage from '../LazyLoadImage/LazyLoadImage';
import { AiFillGithub } from 'react-icons/ai';
import { VscLiveShare } from 'react-icons/vsc';
import { gaLogEvent } from '../React-GA/GA4Tracking';

const Project = ({ data }) => {
  let NumberX = Math.floor(Math.random() * 300 + 1);
  // const gaEventTracker = useAnalyticsEventTracker('Single Project');

  return (
    <>
      <div className="img">
        <figure>
          <LazyLoadImage
            alt={data.name + ': ' + data.desc}
            height={250}
            src={`https://picsum.photos/id/${NumberX}/700/250`}
            width={700}
          />
        </figure>
      </div>
      <div className="body">
        <h2 aria-label={data.name}>{data.name}</h2>
        <p>{data.desc}</p>
      </div>
      <div className="footer">
        <a
          target="_blank"
          rel="noreferrer"
          href={data.github}
          data-title="Github"
          aria-label="Project github link"
          onClick={() => gaLogEvent('Single Project link: ' + data.github, 'Github Name: ' + data.name)}
        >
          <AiFillGithub />
        </a>
        <a
          target="_blank"
          rel="noreferrer"
          href={data.live}
          data-title="Live Site"
          aria-label="Project live link"
          onClick={() => gaLogEvent('Single Project link: ' + data.github, 'Live Site Name: ' + data.name)}
        >
          <VscLiveShare />
        </a>
      </div>
    </>
  );
};

export default Project;
