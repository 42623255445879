import ReactGA from 'react-ga4';

export const initGA4 = () => {
  ReactGA.initialize([
    {
      trackingId: process.env.REACT_APP_GA_TRACKING_ID,
      gaOptions: {
        // Optional Google Analytics options
        userId: 'user-id',
        siteSpeedSampleRate: 100
      },
      gtagOptions: {
        // Optional gtag options
        send_page_view: true
      }
    }
    // {
    //   trackingId: "G-YYYYYYYYYY", // Replace with your second GA4 Measurement ID if you have one
    // },
  ]);
};

export const logPageView = (path = window.location.pathname + window.location.search, title = document.title) => {
  ReactGA.send({ hitType: 'pageview', page: path, title: title });
};
// export const logPageView = () => {
//   ReactGA.send({ hitType: 'pageview', page: window.location.pathname + window.location.search });
// };

export const gaLogEvent = (category, action, label, value, nonInteraction, transport) => {
  ReactGA.event({
    category: category,
    action: action,
    label: label, // Optional
    value: value, // Optional, must be a number
    nonInteraction: nonInteraction, // Optional, true/false
    transport: transport // Optional, beacon/xhr/image
  });
};

export const logTiming = (category, variable, value, label) => {
  ReactGA.timing({
    category,
    variable,
    value,
    label
  });
};
