import React, { useEffect, useState } from 'react';
import AnimatedLetters from '../AnimatedLetters/AnimatedLetters';
import { SiJquery } from 'react-icons/si';
import { FaHtml5, FaCss3Alt, FaJsSquare, FaReact, FaGitAlt, FaSass, FaBootstrap } from 'react-icons/fa';
import { TbBrandNextjs, TbBrandTailwind } from "react-icons/tb";
import { problemSolvingsData, skillsData } from './TechSkillsData';
import Loader from 'react-loaders';
import './About.scss';
import SEO from '../Hooks/SEO';

const About = () => {
  const [letterClass, setLetterClass] = useState('text-animate');
  // useDocumentTitle('Nazmus - About 📝');

  const SkillsData = skillsData();
  const problemSolvingData = problemSolvingsData();

  useEffect(() => {
    setTimeout(() => {
      setLetterClass('text-animate-hover');
    }, 3000);
  }, []);
  return (
    <>
      <div className="container about-page">
        <SEO
          description="Front-end developer with over three years of experience building high-performing, scalable systems. Proficient in JavaScript, 
          TypeScript, React.js, Next.js, and Agile methodologies. Problem-solving skills with a passion for delivering high-quality 
          software solutions. Demonstrated ability to work independently and as part of a team, with excellent communication and 
          mentoring skills."
          href="https://nazmus-home.netlify.app/about"
          title="Nazmus - About 📝"
          type="Experinences in Frontend with Javascript, React, NextJs Development"
        />
        <div className="text-zone">
          <h1>
            <AnimatedLetters letterClass={letterClass} strArray={['A', 'b', 'o', 'u', 't', ' ', 'm', 'e']} idx={15} />
          </h1>
          <div className="paragraph-zone about">
            <h3>Summary</h3>
            <p>
              Front-end developer with over three years of experience building high-performing, scalable systems.
              Proficient in JavaScript, TypeScript, React.js, Next.js, and Agile methodologies. Problem-solving skills
              with a passion for delivering high-quality software solutions. Demonstrated ability to work independently
              and as part of a team, with excellent communication and mentoring skills.
            </p>
          </div>
          <div className="skills">
            <h3>Skills</h3>
            <ul className="skill-list">
              {SkillsData.map((data) => (
                <li key={data._id}>
                  <strong>{data.title} </strong>
                  {data.skill}
                </li>
              ))}
            </ul>
          </div>
          <div className="problem skills">
            <h3>Problem Solving</h3>
            <ul className="skill-list">
              {problemSolvingData.map((data) => (
                <li key={data._id}>
                  <a
                    href={data.link}
                    rel="noreferrer"
                    aria-label={`Blog about: ${data.title} , link: ${data.link}`}
                    data-title="Problem solving"
                  >
                    <strong>{data.title}</strong>
                  </a>
                </li>
              ))}
            </ul>
          </div>
          <div className="education skills">
            <h3>Education</h3>
            <p>
              Graduated from <a href="https://uits.edu.bd/">University of Information Technology and Sciences</a> with a
              four-years
              <span> Bachelor's of Sciences</span> degree in Electrical and Electronic Engineering.
            </p>
          </div>
        </div>

        <div className="stage-cube-cont">
          <div className="cubespinner">
            <div className="face1" style={{ '--i': 1 }}>
              <FaHtml5 color="#5ED4F4" />
            </div>
            <div className="face2" style={{ '--i': 2 }}>
              <FaCss3Alt color="#F06529" />
            </div>
            <div className="face3" style={{ '--i': 3 }}>
              <FaJsSquare color="#28A4D9" />
            </div>
            <div className="face4" style={{ '--i': 4 }}>
              <FaReact color="#EFD81D" />
            </div>
            <div className="face5" style={{ '--i': 5 }}>
              <FaGitAlt color="#EC4D28" />
            </div>
            <div className="face6" style={{ '--i': 6 }}>
              <TbBrandNextjs color="#5ED4F4" />
            </div>
            <div className="face7" style={{ '--i': 7 }}>
              <FaSass color="#EC4D22" />
            </div>
            <div className="face8" style={{ '--i': 8 }}>
              <FaBootstrap color="#EC4D22" />
            </div>
            <div className="face10" style={{ '--i': 10 }}>
              <TbBrandTailwind color="#5ED4F4" />
            </div>
            <div className="face9" style={{ '--i': 9 }}>
              <SiJquery color="#28A4D9" />
            </div>
          </div>
        </div>
      </div>
      <Loader type="pacman" />
    </>
  );
};

export default About;
