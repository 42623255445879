import React, { useEffect, useState } from 'react';
import Loader from 'react-loaders';
import { Link } from 'react-router-dom';
import LogoTitle from '../../assets/images/logo_n.png';
import AnimatedLetters from '../AnimatedLetters/AnimatedLetters';
import Logo from '../Logo/Logo';
import './Home.scss';
import SEO from '../Hooks/SEO';
import { gaLogEvent } from '../React-GA/GA4Tracking';

const Home = () => {
  const [letterClass, setLetterClass] = useState('text-animate');
  // const gaEventTracker = useAnalyticsEventTracker('Home Page');
  // useDocumentTitle('Nazmus Sakib ❤️');

  const nameArray = ['a', 'z', 'm', 'u', 's'];
  const jobArray = ['W', 'e', 'b', ' ', 'D', 'e', 'v', 'e', 'l', 'o', 'p', 'e', 'r', '.'];

  useEffect(() => {
    setTimeout(() => {
      setLetterClass('text-animate-hover');
    }, 3000);
  }, []);

  return (
    <>
      <div className="container home-page">
        <SEO
          description="Experience with ES6, REST API Integration, HTML5, CSS3, SASSS, and the UI frameworks like Bootstrap, and Tailwind CSS. ReactJS front-end development experience with knowledge of unit testing and Vuejs."
          href="https://nazmus-home.netlify.app/"
          title="Nazmus Sakib ❤️"
          type="Frontend, Javascript, React Development"
        />
        <div className="text-zone">
          <h1>
            <span className={letterClass}>H</span>
            <span className={`${letterClass} _10`}>i</span>
            <span className={`${letterClass} _11`}>,</span>
            <br />
            <span className={`${letterClass} _12`}>I</span>
            <span className={`${letterClass} _13`}>'</span>
            <span className={`${letterClass} _14`}>m</span>
            <img
              src={LogoTitle}
              alt="Developer , Frontend Developer, React Developer, Nextjs Developer, Javascript developer, Nazmus Sakib, @saakeeb, nazmus sakib developer"
              loading='lazy'
            />
            <AnimatedLetters letterClass={letterClass} strArray={nameArray} idx={15} />
            <br />
            <AnimatedLetters letterClass={letterClass} strArray={jobArray} idx={22} />
          </h1>
          <h2>Frontend Engineer / Javascript / ReactJs / NextJs </h2>
          <div className="link-zone">
            <Link
              to="/contact"
              className="flat-button"
              aria-label="link to contact page"
              onClick={() => gaLogEvent('Home Page: ', 'Contact', 'Contact Me', 'Contact from Home page')}
            >
              HIRE ME
            </Link>
            <Link
              to="/experiences"
              className="flat-button"
              aria-label="link to experience page"
              onClick={() => gaLogEvent('Home Page: ', 'Experiences', 'Experiences', 'Experiences from Home page')}
            >
              Experiences
            </Link>
          </div>
        </div>
        <Logo></Logo>
      </div>
      <Loader type="pacman" />
    </>
  );
};

export default Home;
