import React from 'react';
import ConvertKitForm from 'convertkit-react';

export const formUrl = process.env.REACT_APP_CONVERT_KIT_FORM_ID;

const BlogSubscription = () => {
  const config = {
    formId: parseInt(formUrl),
    emailPlaceholder: 'Enter Your Email Address',
    submitText: 'Subscribe',
    format: 'inline',
    newTab: true
  };

  return (
    <>
      <ConvertKitForm {...config} />
    </>
  );
};

export default BlogSubscription;
