import React from 'react';
import LazyLoadImage from '../LazyLoadImage/LazyLoadImage';
import ShareToMedia from '../Common/ShareToMedia';
import { socialMedia } from '../Common/SocialMedia';
import { gaLogEvent } from '../React-GA/GA4Tracking';

const Blog = ({ data }) => {
  let NumberX = Math.floor(Math.random() * 300 + 1);
  // const gaEventTracker = useAnalyticsEventTracker('Single Blog');

  return (
    <>
      <div className="img">
        <figure>
          <LazyLoadImage
            alt={data.name + ': ' + data.desc}
            height={250}
            src={`https://picsum.photos/id/${NumberX}/700/250`}
            width={700}
          />
        </figure>
      </div>
      <div className="body">
        <h2 aria-label={data.name}>{data.name}</h2>
        <p>
          {data.desc}{' '}
          <a
            href={data.link}
            target="_blank"
            rel="noreferrer"
            aria-label={`Blog about: ${data.desc} , link`}
            data-title="Blog Site"
            onClick={() => gaLogEvent('Single Blog Link: ', data.link, 'Name', data.name)}
          >
            read more...
          </a>
        </p>
      </div>
      <div className="footer">
        {socialMedia.map((media, index) => (
          <ShareToMedia key={index} link={data.link} title={data.name} description={data.desc} media={media} />
        ))}
      </div>
    </>
  );
};

export default Blog;
