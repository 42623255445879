import { useRef, useEffect } from 'react';

const LazyLoadImage = ({ src, alt, height, width }) => {
  const imageRef = useRef(null);
  useEffect(() => {
    const options = {};
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          if (imageRef.current) {
            imageRef.current.src = src;
            imageRef.current.alt = alt;
            imageRef.current.height = height;
            imageRef.current.width = width;
            observer.unobserve(imageRef.current);
          }
        }
      });
    }, options);

    if (imageRef.current) {
      observer.observe(imageRef.current);
    }

    return () => {
      observer.disconnect();
    };
  }, [src, alt, height, width]);

  return <img ref={imageRef} alt="" loading='lazy'/>;
};

export default LazyLoadImage;
