import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import Loader from 'react-loaders';
import AnimatedLetters from '../AnimatedLetters/AnimatedLetters';
import './Projects.scss';
import { projectDatas } from './ProjectData';
import Project from './Project';
import anime from 'animejs';
import useAnalyticsEventTracker from '../React-GA/useAnalyticsEventTracker';
import SEO from '../Hooks/SEO';
import Button from '../Common/Button';

const Projects = () => {
  const [letterClass, setLetterClass] = useState('text-animate');
  const [visibleProjects, setVisibleProjects] = useState(3);
  const datas = projectDatas();
  const gaEventTracker = useAnalyticsEventTracker('Projects Page');
  // useDocumentTitle('Nazmus - Projects 📋');

  useEffect(() => {
    const image = document.querySelectorAll('.project');
    anime({
      targets: image,
      scale: [
        { value: 0.1, easing: 'easeOutSine', duration: 500 },
        { value: 1, easing: 'easeInOutQuad', duration: 1200 }
      ],
      delay: anime.stagger(200, { grid: [14, 5], from: 'center' })
    });
  }, []);

  function loadMore() {
    setVisibleProjects(visibleProjects === 3 ? datas.length : 3);
    gaEventTracker('Show More Projects', 'Projects Click');
  }

  useEffect(() => {
    setTimeout(() => {
      setLetterClass('text-animate-hover');
    }, 3000);
  }, []);

  return (
    <>
      <div className="container project-page">
        <SEO
          description="Movie Archives, Blog Lists, Landing Page, Gallary App"
          href="https://nazmus-home.netlify.app/projects"
          title="Nazmus - Projects 📋"
          type="Projects about  Frontend, Javascript, React Developent"
        />
        <div className="text-zone">
          <h1>
            <AnimatedLetters letterClass={letterClass} strArray={['P', 'r', 'o', 'j', 'e', 'c', ' t', 's']} idx={15} />
          </h1>
          <div className="paragraph-zone">
            <p>Please give me a star in the Github repository, it will inspire me.</p>
          </div>
          <div className="projects">
            {datas.slice(0, visibleProjects).map((data, index) => (
              <div className="project" aria-label="project description" key={index}>
                <Project data={data} />
              </div>
            ))}
          </div>
          <div className="load-more">
            {datas.length > 3 ? (
              <Button
                class_name="flat-button"
                area_label="Show more/less projects"
                handleClick={() => loadMore()}
                text={visibleProjects === 3 ? 'Show More' : 'Show Less'}
              />
            ) : (
              ''
            )}
          </div>
        </div>
      </div>

      <Loader type="pacman" />
    </>
  );
};

export default Projects;
